// ============
// VARIABLES
// Able to include them in other classes.
// ============

$xs: 5px;
$sm: 10px;
$md: 20px;
$lg: 30px;
$xl: 40px;
$xxl: 50px;

$sidebar-dropdown-background-dark: #212121;
$sidebar-dropdown-background-light: #dddddd;

$sidebar-item-background-dark: #3a3a3ac0;
$sidebar-item-background-light: #eeeeee96;

// ============
// MIXINS
// Able to include them in other classes.
// ============

@mixin pre-line {
    white-space: pre-line;
}

@mixin pre-wrap {
    white-space: pre-wrap;
}

@mixin nowrap {
    white-space: nowrap;
}

@mixin iframe {
    border: 0;
    overflow: hidden;
}

@mixin typography-media($line-clamp) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: $line-clamp;
}

@mixin background-transition($duration) {
    transition: background-color $duration ease;
}

@mixin ticker-image {
    margin-left: -3px;
}

@mixin welcome-paper {
    margin: $sm;
    display: flex;
    flex-direction: column;
    text-align: center;
}

@mixin cover-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    object-fit: cover;
    width: 100%;
}

@mixin contain-image {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    object-fit: contain;
    width: 100%;
}

@mixin log-out-paper {
    margin: $md;
    display: flex;
    flex-direction: column;
    text-align: center;
}

@mixin italic {
    font-style: italic;
}

// Don't highlight the links.
@mixin hide-link {
    color: inherit;
    text-decoration: inherit;
}

// Hide scrollbar for all browsers.
@mixin hide-scrollbar {
    scrollbar-width: none;
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}

// Text overflow with ellipsis (...).
@mixin ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

// ============
// GENERAL STYLES
// HTML body, page loaders, etc.
// ============

body {
    margin: 0;
    font-family: "Space Grotesk", "Poppins", "Libre Bodoni", "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    zoom: 1;
}

// Page-wide loader.
.page-loader {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 24, 0.834) url("../public/images//other/candles_transparent.gif") center/75% no-repeat;
    z-index: 1;
}

// Center child components.
// As the only child is the background image, which is anyway centered, there is no need for flex display & justified content.
.component-loader-parent {
    width: 100%;
    height: 100vh;
}

// Child loader component.
.component-loader {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0) url("../public/images//other/candles_transparent.gif") center/100% no-repeat;
    z-index: 1;
}

.component-loader-empty {
    width: 100%;
    height: 15vh;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0) url("../public/images/other/market_not_brr.gif") center/contain no-repeat;
    z-index: 1;
}

.list-subheader {
    @include background-transition(1s);
    @include ellipsis;
}

.hide-scrollbar {
    @include hide-scrollbar;
}

.hide-link {
    @include hide-link;
}

// Multiline without unnecessary spaces.
.pre-line {
    @include pre-line;
}

// Multiline with spaces.
.pre-wrap {
    @include pre-wrap;
}

// Single line.
.nowrap {
    @include nowrap;
}

// Text overflow with ellipsis (...).
.ellipsis {
    @include ellipsis;
}

// ============
// MUI STYLING
// We can overwrite MUI component styles as well.
// ============

.MuiTypography-root {
    font-family: "Space Grotesk", "Poppins", "Libre Bodoni", "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif;
}

.MuiListItemIcon-root {
    min-width: $lg;
}

.MuiListItemText-root {
    margin-top: 2px;
    margin-bottom: 2px;
}

.MuiDataGrid-cell {
    font-size: 1em;
}

.MuiInputBase-root {
    font-size: 1em;
}

.MuiDialogContent-root {
    @include pre-line;
    padding-bottom: $sm;
}

.MuiDialog-paper {
    max-width: 625px;
}

/* Hide sidebar's scrollbar. */
.MuiPaper-root {
    @include hide-scrollbar;
    min-width: unset; // Fix possible overflow for small resulutions.
}

/* Hide table scrollbar. */
.MuiDataGrid-virtualScroller {
    @include hide-scrollbar;
}

// ============
// MAIN PAGES
// Home, about, welcome, etc.
// ============

.main-page-box {
    display: flex;
    min-height: 100vh;
}

.main-component-box {
    @include background-transition(1s);
    flex-grow: 1;
}

.welcome-paper {
    @include welcome-paper;
    padding: $xl;
    margin: $xl;
    margin-bottom: $sm;

    @media (max-width: 600px) {
        padding: $md;
        margin: $md;
    }
}

.welcome-page {
    background-color: #212121;
}

.welcome-header-image {
    @include cover-image;
    background-image: url("../public/images/stock_photos/stock_market_3.png");
    min-height: 40vh;
    width: 100%;
}

.welcome-header-text {
    padding: $lg;
    margin: $lg;
    margin-bottom: $sm;
    color: white;
    background-color: #121212e6;

    @media (max-width: 600px) {
        padding: $md;
        margin: $md;
    }
}

.welcome-header-button {
    padding: $sm;
    margin: $lg;
    margin-bottom: $sm;
    color: white;
    background-color: #121212e6;

    @media (max-width: 600px) {
        padding: $xs;
        margin: $md;
    }
}

.drawer-toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 1em;
    padding-right: 1em;
}

.menu-item-selection {
    width: 100%;
}

.dialog-selection-list {
    min-height: 25vh;
    max-height: 30vh;
    overflow-y: scroll;
}

.dialog-selection-list-element {
    display: flex;
    justify-content: space-between;
}

// ============
// SIDEBAR
// Navigation sidebar.
// ============

/* Selected sidebar elements get custom styling. */
.sidebar-link.active {
    span {
        font-weight: bold;
    }

    > div {
        background-color: #0c63d433;
    }

    svg {
        color: #0ca5d4;
    }
}

.sidebar-parent-dark:has(.sidebar-link.active) > div,
.sidebar-parent-light:has(.sidebar-link.active) > div {
    @include background-transition(1s);
}

.sidebar-parent-dark:has(.sidebar-link.active) > div {
    background-color: $sidebar-dropdown-background-dark;
}

.sidebar-parent-light:has(.sidebar-link.active) > div {
    background-color: $sidebar-dropdown-background-light;
}

.sidebar-parent-dark:has(.sidebar-link.active) > .sidebar-collapse,
.sidebar-parent-light:has(.sidebar-link.active) > .sidebar-collapse {
    @include background-transition(1s);
}

.sidebar-parent-dark:has(.sidebar-link.active) > .sidebar-collapse {
    background-color: $sidebar-item-background-dark;
}

.sidebar-parent-light:has(.sidebar-link.active) > .sidebar-collapse {
    background-color: $sidebar-item-background-light;
}

.sticky-sidebar {
    position: sticky;
    top: 0;
    height: 100vh; // Enable sidebar scroll.
    overflow-y: auto;
}

.mobile-sidebar {
    z-index: 1300;
    height: 100%; // Mobile empty space fix.
}

.nested-list {
    width: 100%;
}

.nested-list-item-icon {
    min-width: $lg;
}

.wider-list-item-icon {
    min-width: $xxl;
}

// ============
// NOTIFICATIONS
// Notifications sidebar and alerts.
// ============

.stack {
    width: 90%;
    margin-bottom: $sm;
}

.notification {
    @include pre-line;
    width: 100%;
    text-align: left;
}

.notification-drawer {
    z-index: 1201;
}

.notification-drawer-stack {
    width: 100%;
    padding: 2%;
    text-align: center;
    align-items: center;
}

.notification-toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

// ============
// APPBAR
// Appbar sidebar.
// ============

.appbar-toolbar {
    padding-right: 24px;
}

.appbar-icon-button {
    margin-left: $sm;
}

.appbar-menu-icon {
    margin-right: 36px;
}

.appbar-title {
    flex-grow: 1;
}

.appbar-description {
    flex-grow: 2;
}

// ============
// AUTH PAGES
// Sign up, sign in, logout, etc.
// ============

.auth-box {
    margin-top: $md;
}

// ============
// MEDIA CARD
// News snippets with image and text.
// ============

.media-card {
    height: 100px;
    margin-bottom: $sm;
}

.card-content {
    height: 180px;
    padding-top: 4px;
}

.media-card-title {
    @include typography-media(2);
}

.media-card-summary {
    @include typography-media(4);
}

.media-card-summary-full {
    @include typography-media(10);
}

.media-card-source {
    @include italic;
    margin-top: $sm;
    text-align: center;
}

// ============
// COMPONENTS
// Styles used in HTML or MUI components.
// ============

button {
    max-width: -webkit-fill-available;
}

.avatar {
    margin: $xs;
}

.accordion {
    margin: 0;
    text-align: center;
}

.accordion-summary {
    margin: 0;
    min-height: 60px;
    padding-left: 3em;
}

.chart {
    width: 100%;
    height: 100%;
}

.paper {
    display: flex;
    // CSS code overwrites sx props.
    padding: $sm;
    margin: $sm;
    flex-direction: column;
    align-items: inherit;
    text-align: inherit;
    min-width: inherit;
    max-height: inherit;
    height: inherit;
    width: inherit;
}

.paper-typography {
    padding-top: $sm;
    padding-bottom: $sm;
}

.table {
    width: 100%;
}

.table-text-cell {
    padding: 12px 1px;
}

.table-header {
    font-size: 100%;
    font-weight: bold;
}

.table-nested {
    padding-left: 50px;
}

.form {
    margin-top: $md;
}

.form-label {
    margin-top: $lg;
}

.form-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $xl $md;
}

.container {
    margin-top: $sm;
    margin-bottom: $sm;
}

.box {
    margin-top: $md;
}

.button {
    margin-top: $md;
    margin-bottom: $sm;
}

/* Custom theme for react-mde. */
.react-mde-custom-theme {
    background-color: $sidebar-dropdown-background-dark; /* Dark background color */
    color: #ffffff; /* Light text color */

    .image-tip,
    .svg-icon,
    button {
        background-color: $sidebar-dropdown-background-dark; /* Dark background color */
        color: #ffffff; /* Light text color */
    }
}

// ============
// IMAGES
// Styles for images.
// ============

.contain-image {
    @include contain-image;
    height: 350px;
}

.cover-image {
    @include cover-image;
    height: 350px;
}

// ============
// MISC.
// Uncategorized styles.
// ============

.xs-margin {
    margin: $xs;
}

.xs-margin-top-bottom {
    margin-top: $xs;
    margin-bottom: $xs;
}

.sm-margin {
    margin: $sm;
}

.sm-margin-top-bottom {
    margin-top: $sm;
    margin-bottom: $sm;
}

.md-margin {
    margin: $md;
}

.md-margin-top-bottom {
    margin-top: $md;
    margin-bottom: $md;
}

.lg-margin {
    margin: $lg;
}

.xl-margin {
    margin: $xl;
}

.xxl-margin {
    margin: $xxl;
}

.xs-padding {
    padding: $xs;
}

.sm-padding {
    padding: $sm;
}

.md-padding {
    padding: $md;
}

.lg-padding {
    padding: $lg;
}

.xl-padding {
    padding: $xl;
}

.xxl-padding {
    padding: $xxl;
}

.xxs-font {
    font-size: 0.5em;
}

.xs-font {
    font-size: 0.75em;
}

.sm-font {
    font-size: 0.9em;
}

.md-font {
    font-size: 1em;
}

.lg-font {
    font-size: 1.25em;
}

.xl-font {
    font-size: 1.5em;
}

.xxl-font {
    font-size: 2em;
}

.xxxl-font {
    font-size: 3em;
}

.italic {
    @include italic;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.tabs {
    div > div > button {
        font-size: smaller;
    }
}

.code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: 90%;
}

.blurred {
    filter: blur(3px);
}

.custom-input-text {
    @include pre-line;
    font-weight: bold;
    margin-top: 10px;
}

.search-filter-text {
    margin-right: $sm;
}

.copyright {
    margin: $xl $xl;
    white-space: pre-line;
}

.loaded-iframe {
    @include iframe;
    display: inherit;
}

.non-loaded-iframe {
    @include iframe;
    display: none;
}

.loaded-image {
    display: inherit;
}

.non-loaded-image {
    display: none;
}

.snake-iframe {
    width: 100%;
    height: 100%;
}

.impression-text {
    font-weight: bold;
    font-size: $lg;
    padding: $sm;
    margin: $sm;
}

.center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.grayscale {
    filter: grayscale(100%);
}

.opacity {
    opacity: 0.5;
}

// ============
// ANIMATIONS
// Animated styles.
// ============

@mixin flashing-animation($color, $duration) {
    @keyframes #{$color} {
        0% {
            color: inherit;
        }
        50% {
            color: $color;
        }
        100% {
            color: inherit;
        }
    }

    animation: $color $duration infinite;
}

@mixin spin($duration) {
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(-360deg);
        }
    }

    animation: spin $duration infinite linear;
}

.spin-fast {
    @include spin(1s);
}

.spin-slow {
    @include spin(2s);
}

.flashing-green {
    @include flashing-animation(green, 1.5s);
}

.flashing-red {
    @include flashing-animation(red, 1.5s);
}
